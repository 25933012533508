import PrivacyPolicyHeader from "../../modules/agreements/privacy-policy/PrivacyPolicyHeader";
import PrivacyPolicyBody from "../../modules/agreements/privacy-policy/PrivacyPolicyBody";

const PrivacyPolicyPage = () => {
   return (
      <>
         <PrivacyPolicyHeader />
         <PrivacyPolicyBody />
      </>
   );
};

export default PrivacyPolicyPage;
