import './OurServices.css';

import React from 'react';

const OurServices = (props) => {
    const { services } = props;
    return (
        <div className="services-container">
            <p className="services-title">Our Services</p>
            <div className='services-list'>
                {services.map((service, index) => (
                    <React.Fragment key={service.id}>
                        <div className='service' key={service.id}>
                            <div className='img-container'>
                                <img src={service.img} alt={service['service-title']} className='service-img' style={{ width: service.width, height: service.height }} />
                            </div>
                            <div className='service-details'>
                                <p className='service-title'>{service['service-title']}</p>
                                <p className='provided-service'>{service['provided-service']}</p>
                            </div>
                        </div>
                        {index !== services.length - 1 && <div className="service-divider"></div>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default OurServices;