import './LimitedOffer.css';

import React, { useRef, useState } from 'react';


const LimitedOffer = (props) => {
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust scroll speed
        containerRef.current.scrollLeft -= walk;
        setStartX(x);
    };

    const handleWheel = (e) => {
        containerRef.current.scrollLeft += e.deltaY;
    };

    return (
        <div className="container-limited">
            <p className="title">Limited Offer</p>
            <div
                className='scrollable-image'
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onMouseMove={handleMouseMove}
                onWheel={handleWheel}
            >
                {props.images.map((image, index) => (
                    <img key={index} src={image} alt={`Image ${index + 1}`} draggable="false" />
                ))}
            </div>
        </div>
    );
}

export default LimitedOffer;