import React from "react";
import { Outlet } from "react-router";
import ScrollToTop from "../../components/ScrollTop/ScrollTop";

const AgreementLayout = () => {
   return (
      <>
         <Outlet />
         <ScrollToTop />
      </>
   );
};

export default AgreementLayout;
