import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicyHeader = () => {
   return (
      <header className={styles.header_container}>
         <h1 className={styles.header_title}>Global Pinoy Travel and Tours Privacy Policy</h1>
         <span className={styles.subheader_title}>Updated October 07, 2024</span>
      </header>
   );
};

export default PrivacyPolicyHeader;
