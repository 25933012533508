import styles from "./TermsAndConditions.module.css";

const TermsAndConditionHeader = () => {
   return (
      <header className={styles.header_container}>
         <h1 className={styles.header_title}>Global Pinoy Travel and Tours Terms and Conditions</h1>
         <span className={styles.subheader_title}>Updated October 07, 2024</span>
      </header>
   );
};

export default TermsAndConditionHeader;
