import styles from "./PrivacyPolicy.module.css";
import { PRIVACY_POLICY_DATA } from "../../../constants/agreement";

const PrivacyPolicyBody = () => {
   return (
      <article className={styles.content_container}>
         {PRIVACY_POLICY_DATA.map(({ title, content }) => {
            return (
               <>
                  {title && <h5 className={styles.section_title}>{title}</h5>}

                  {content &&
                     content.map((ctn) => {
                        const isContentBulletList = typeof ctn === "object";

                        return (
                           <>
                              {isContentBulletList ? (
                                 <>
                                    <ul className={styles.bullet_content_container}>
                                       {ctn.map((c) => {
                                          return <li>{c}</li>;
                                       })}
                                    </ul>
                                 </>
                              ) : (
                                 <p>{ctn}</p>
                              )}
                           </>
                        );
                     })}
               </>
            );
         })}
      </article>
   );
};

export default PrivacyPolicyBody;
