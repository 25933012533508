import React from "react";

const FlightsPage = () => {
   return (
      <div>
         <iframe
            src="https://ph.via.com/b2bwl/gprs"
            title="via"
            style={{ width: "100%", height: "1000px" }}
         ></iframe>
      </div>
   );
};

export default FlightsPage;
