import "../styles/global.css";
import React from "react";
import { Outlet } from "react-router";
import Header from "../components/Header/Header";
import Footer from "../layouts/Footer/Footer";

const RootLayout = () => {
   return (
      <>
         <Header />
         <Outlet />
         <Footer />
      </>
   );
};

export default RootLayout;
