import React from "react";
import styles from "./TermsAndConditions.module.css";
import { TERMS_AND_CONDITIONS_SECTIONS_DATA } from "../../../constants/agreement";

const TermsAndConditionsSections = () => {
   return (
      <article className={styles.content_container}>
         {TERMS_AND_CONDITIONS_SECTIONS_DATA.map((section, index1) => (
            <div key={`section-${index1}`}>
               <span className={styles.section_title}>{section.title}</span>
               <ol className={styles.section_list}>
                  {section.list.map((section2, index2) => (
                     <li key={`section2-${index1}-${index2}`} className={styles.section_list_title}>
                        <span dangerouslySetInnerHTML={{ __html: section2.title }} />
                        <ol className={styles.section_list_lower_alpha}>
                           {section2.list.map((section3, index3) => (
                              <li
                                 key={`section3-${index1}-${index2}-${index3}`}
                                 className={styles.section_list_lower_alpha_item}
                              >
                                 <span dangerouslySetInnerHTML={{ __html: section3.content }} />
                                 {section3.list && (
                                    <ol className={styles.section_list_upper_roman}>
                                       {section3.list.map((section4, index4) => (
                                          <li
                                             key={`section4-${index1}-${index2}-${index3}-${index4}`}
                                             className={styles.section_list_upper_roman_item}
                                          >
                                             <span dangerouslySetInnerHTML={{ __html: section4 }} />
                                          </li>
                                       ))}
                                    </ol>
                                 )}
                              </li>
                           ))}
                        </ol>
                     </li>
                  ))}
               </ol>
            </div>
         ))}
      </article>
   );
};

export default TermsAndConditionsSections;

/*
 {TERMS_AND_CONDITIONS_SECTIONS_DATA.map((section) => {
            return (
               <React.Fragment>
                  <span className={styles.section_title}>{section.title}</span>

                  <ol className={styles.section_list}>
                     YOUR USE OF THE PLATFORM
                     <li className={styles.section_list_title}>
                        <ol className={styles.section_list_lower_alpha}>
                           {section.list.map((section2) => {
                              return (
                                 <React.Fragment key={section2.title}>
                                    {section2.list.map((section3) => {
                                       return (
                                          <li className={styles.section_list_lower_alpha_item}>
                                             {section3.content}
                                             <ol key={section3.title} className={styles.section_list_upper_roman}>
                                                {section3.list &&
                                                   section3.list.map((section4) => {
                                                      return (
                                                         <>
                                                            <li
                                                               key={section4}
                                                               className={styles.section_list_upper_roman_item}
                                                               dangerouslySetInnerHTML={{ __html: section4 }}
                                                            />
                                                         </>
                                                      );
                                                   })}
                                             </ol>
                                          </li>
                                       );
                                    })}
                                 </React.Fragment>
                              );
                           })}
                        </ol>
                     </li>
                  </ol>
               </React.Fragment>
            );
         })}
*/
