import React, { useState, useEffect } from 'react';

import './Carousel.css';

const Carousel = ({ images, interval = 3000 }) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            goToNextSlide();
        }, interval);

        return () => {
            clearInterval(intervalId);
        }
    }, [currentImageIndex]);

    const goToNextSlide = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className='carousel'>
            <img className='carousel__img' src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} />
        </div>
    );
}

export default Carousel;