import TermsAndConditionsHeader from "../../modules/agreements/terms-and-conditions/TermsAndConditionsHeader";
import TermsAndConditionsIntro from "../../modules/agreements/terms-and-conditions/TermsAndConditionsIntro";
import TermsAndConditionsSections from "../../modules/agreements/terms-and-conditions/TermsAndConditionsSections";

const TermsAndConditionsPage = () => {
   return (
      <>
         <TermsAndConditionsHeader />
         {/* <TermsAndConditionsIntro /> */}
         <TermsAndConditionsSections />
      </>
   );
};

export default TermsAndConditionsPage;
