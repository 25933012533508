import "./TravelAllAroundTheWorld.css";

import img1 from "../../../assets/Images/Travel all around the world/img1.jpg";
import img2 from "../../../assets/Images/Travel all around the world/img2.jpg";
import img3 from "../../../assets/Images/Travel all around the world/img3.jpg";
import img4 from "../../../assets/Images/Travel all around the world/img4.jpg";

const TravelAllAroundTheWorld = () => {
   return (
      <div className="container">
         <div className="images">
            <div className="column">
               <img className="img1" src={img1} alt="Image1" />
               <img className="img3" src={img3} alt="Image3" />
            </div>
            <div className="column">
               <img className="img2" src={img2} alt="Image2" />
               <img className="img4" src={img4} alt="Image4" />
            </div>
         </div>
         <div className="text">
            <h2>Travel All Around The World</h2>
            <p className="main__point">
               Embark on unforgettable adventures with our diverse range of
               travel tours.
            </p>
            <p className="supporting__point">
               Explore the world with confidence and comfort through our
               comprehensive travel and tours offerings. Designed to cater to
               every traveler's needs and preferences, our diverse range of
               packages promise unforgettable experiences in destinations near
               and far. Whether you're craving the excitement of urban
               exploration or the serenity of nature retreats, we have the
               perfect itinerary waiting for you. Our seasoned guides and
               meticulously planned logistics ensure a smooth and hassle-free
               journey, allowing you to focus solely on soaking in the beauty
               and culture of each destination.
            </p>
         </div>
      </div>
   );
};

export default TravelAllAroundTheWorld;
