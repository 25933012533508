import {
   createHashRouter,
   createRoutesFromElements,
   Route,
} from "react-router-dom";
import RootLayout from "./pages/layout";
import AgreementLayout from "./pages/agreement/layout";
import PrivacyPolicyPage from "./pages/agreement/privacy-policy";
import ContactUsPage from "./pages/contact-us";
import HomePage from "./pages/home";
import FlightsPage from "./pages/flights";
import TermsAndConditionsPage from "./pages/agreement/terms-and-condition";

export const router = createHashRouter(
   createRoutesFromElements(
      <Route element={<RootLayout />}>
         <Route index element={<HomePage />} />

         <Route path="contact-us" element={<ContactUsPage />} />
         <Route path="flights" element={<FlightsPage />} />

         <Route path="agreement" element={<AgreementLayout />}>
            <Route
               path="terms-and-conditions"
               element={<TermsAndConditionsPage />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
         </Route>
      </Route>
   )
);
