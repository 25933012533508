// TERMS AND CONDITIONS

export const INTRODUCTION_DATA = [
   {
      content: [
         `Welcome to the Global Pinoy Travel and Tours Platform Terms and Conditions (“<strong style="font-weight: 600;">Terms</strong>”). These
          Terms apply to the use of the Global Pinoy Travel and Tours Platform and the services offered
          through it. Your use of the Global Pinoy Travel and Tours Platform indicates your acceptance of the
          <strong style="font-weight: 600;">Terms</strong>, including the policies incorporated into them by reference..`,

         `These <strong style="font-weight: 600;">Terms</strong> and the documents referred to in them, including but not limited to our Booking Policy,
          Pricing Policy, Reservation Policy, Cancellation Policy, Refund Policy, and Rewards Program,
          constitute a legally binding agreement between you and Global Pinoy Travel and Tours. If you do not
          agree to any part of these <strong style="font-weight: 600;">Terms</strong>, you should stop using the Platform immediately.`,

         `These <strong style="font-weight: 600;">Terms</strong> are important because they describe:`,

         [
            `the rules, terms and conditions that apply to your use of and bookings on the Global Pinoy
             Travel and Tours Platform; and how to resolve any disputes that may`,

            `how to resolve any disputes that may arise from your use of the Global Pinoy Travel and
             Tours Platform.`,
         ],

         `<strong style="font-weight: 600;">BEFORE SUBMITTING AN ACCOUNT REGISTRATION FORM AND/OR USING ANY OF THE SERVICES
          ON THE PLATFORM, PLEASE READ THESE TERMS CAREFULLY.</strong>`,

         `Accessing materials on this Platform by certain persons in certain countries and territories may not
          be lawful, and we make no representation that materials on this Platform are appropriate or
          available for use in all locations where the Platform can be accessed.`,

         `When we refer to “<strong style="font-weight: 600;">Global Pinoy Travel and Tours</strong>”, “<strong style="font-weight: 600;">We</strong>”, “<strong style="font-weight: 600;">Our</strong>” or “<strong style="font-weight: 600;">Us</strong>” in these <strong style="font-weight: 600;">Terms</strong>, we mean
          Global Pinoy Travel and Tours Inc. including its company affiliates and subsidiaries.`,

         `Global Pinoy Travel and Tours operates a platform that empowers you to discover, search, book and
          pay for various products and services which are supplied, offered and/or operated by third parties
          (“<strong style="font-weight: 600;">Travel Suppliers</strong>”), which may include, without limitation:`,

         [
            `air and air-related transport services (“<strong style="font-weight: 600;">Flights</strong>”);`,
            `lodging and temporary accommodation, including but not necessarily limited to hotels,`,
            `hostels, serviced apartments, bed-and-breakfasts, rooms for rent, and cottages
             (“<strong style="font-weight: 600;">Accommodations</strong>”)`,
         ],

         `(each a “<strong style="font-weight: 600;">Travel Product</strong>”), through a convenient portal, the facilitation of which is individually and
          collectively known as our “<strong style="font-weight: 600;">Services</strong>”. You may access our Service through our website (“<strong style="font-weight: 600;">Website</strong>”)
          and mobile application (“<strong style="font-weight: 600;">App</strong>”), which is soon-to-follow, collectively known as our “<strong style="font-weight: 600;">Platform</strong>”.`,

         `We work with our affiliates and other third-party partners (“<strong style="font-weight: 600;">Partners</strong>”) to facilitate the listing and
          reservation of certain Travel Products. Where this is the case, the Partner’s terms and conditions
          and/or privacy policy will govern the relationship between you and our Partner for the booking of
          the relevant <strong style="font-weight: 600;">Travel Product(s)</strong> instead.`,

         `When we use “<strong style="font-weight: 600;">you</strong>”, “<strong style="font-weight: 600;">your</strong>”, or “<strong style="font-weight: 600;">User</strong>”, we refer to anyone who accesses or uses our Platform and
          Services.`,

         `If you have any questions, comments, complaints, or claims with respect to our Platform or these
          <strong style="font-weight: 600;">Terms</strong>, you may contact *Global Pinoy Travel and Tours’s Hotline and Helpdesk Email Address*`,
      ],
   },
];

export const TERMS_AND_CONDITIONS_SECTIONS_DATA = [
   {
      title: "SECTION A: PLATFORM TERMS",
      list: [
         {
            title: "YOUR USE OF THE PLATFORM",
            list: [
               {
                  content: `Our Platform is the property of Global Pinoy Travel and Tours and our licensors. To
                            this end, we grant you a non-exclusive, non-transferable, non-sub-licensable,
                            revocable, and limited license to access our Platform to enjoy personal, non-
                            commercial use of our Services in accordance with these Terms. Except for this
                            limited license, we do not grant you any other rights or licenses with respect to our
                            Platform. Any rights or licenses not expressly granted herein are reserved. You
                            represent and warrant to us that your access to, and use of, our Platform is intended
                            only for personal, non-commercial use.`,
               },
               {
                  content: `You will not use the Platform for any purpose that is unlawful or prohibited by these
                   Terms, or for any other purpose not reasonably intended by us. By way of example,
                   and not as limitation, you agree not to use our Platform and/or its content to:`,
                  list: [
                     `make false, fraudulent, or speculative bookings on our Platform;`,

                     `pass off or attempt to pass off our Platform as the product of anyone other
                      than Global Pinoy Travel and Tours, including by removing, altering or
                      replacing any notices of authorship, trademarks, business names, logos or
                      other designations of origin;`,

                     `use manual or automated software or processes to monitor, copy,
                      extract, export or otherwise scrape any material displayed on our Platform,
                      including text, graphics, photographs, images, illustrations, audio, video,
                      data, ratings, reviews, and other information (“<strong style="font-weight: 600;">Content</strong>”). Such manual or
                      automated software or processes include datamining, scraping, spidering,
                      indexing, storing or rehosting the Content outside our Platform;`,

                     `disassemble, reverse engineer, decompile or modify any software or
                      application contained in or available on our Platform in whole or in part, or
                      otherwise attempt to obtain or access the source code of our Platfrom;
                      v. interfere with or disrupt our Platform, or servers or networks connected to
                      our Platform;`,

                     `violate the security of any network, including cracking passwords or
                      encryption codes, transferring or storing any illegal material, or otherwise
                      obtaining the password, account or private information from any other User
                      of our Platform;`,

                     `obtain or attempt to obtain unauthorized access, via whatever
                      means, to any Global Pinoy Travel and Tours and its Partners’ systems;`,

                     `‘frame’, ‘mirror’ or otherwise incorporate any part of the Platform
                      into any other website or deep-link to any portion of the Platform (including,
                      without limitation, purchase paths) for any purpose without our express
                      prior written permission;`,

                     `post or transmit, or cause to be posted or transmitted: (a) any
                      material that is libelous, defamatory, obscene, pornographic, abusive,
                      offensive, fraudulent, misleading, harassing or profane; (b) malicious
                      content including malware, Trojan horses, or viruses, or which otherwise
                      interferes with any User’s access to our Platform; and (c) unsolicited mass
                      mailing or other forms of spam, including junk mail or chain letters;`,

                     `manipulate identifiers, including by forging headers, in order to disguise the
                      origin of any posting that you deliver;`,

                     `do anything else which could cause damage to the Platform, Global
                      Pinoy Travel and Tours and its Parnters, or would otherwise have a negative
                      impact on the Platform, Global Pinoy Travel and Tours, and its Partners; and`,

                     `infringe any applicable law, statute, ordinance or regulation and any
                      rights of us and/or any third party, including intellectual property rights or
                      proprietary rights.`,
                  ],
               },
               {
                  content: `If, based on your user pattern, Global Pinoy Travel and Tours suspects that you have
                            performed any of the prohibited activities under these Terms, Global Pinoy Travel
                            and Tours has the right to block your user account or deny your entitlement to any
                            benefits under the Referral Program and/or Rewards Program (as defined below).`,
               },
            ],
         },
         {
            title: "YOUR ACCOUNT",
            list: [
               {
                  content: `In the course of using our Platform, you may need to register and maintain either a
                            Free Account, Monthly Account, Annual or Business Account, and Affiliate Account
                            with us (“<strong style="font-weight: 600;">Global Pinoy Travel and Tours Account</strong>”). When you register a Global
                            Pinoy Travel and Tours Account with us, you agree to provide and maintain true,
                            current, and complete information during registration. When using an email
                            address, you agree not to use an email address with the name of another person
                            with the intent to impersonate, or a name or trademark that is subject to the rights
                            of another person or entity other than you without appropriate authorization.`,
               },
               {
                  content: `You are responsible for maintaining the confidentiality of the information and
                            password associated with your Global Pinoy Travel and Tours Account, and for all
                            activities occurring under your Global Pinoy Travel and Tours Account including all
                            charges incurred. Global Pinoy Travel and Tours shall be entitled to consider any
                            person who gains access to the Platform and Services using your Global Pinoy Travel
                            and Tours Account log-in details as an individual acting upon your authorization,
                            without any further inquiry or investigation. Where applicable, references to your
                            use and/or access of the Platform and Services shall be deemed to include the use
                            and/or access of the Platform and Services by an individual authorized by you.
                            Notwithstanding this, Global Pinoy Travel and Tours shall be entitled at our own
                            discretion to decline to act or refrain from acting promptly upon any instructions
                            from your Global Pinoy Travel and Tours Account and to investigate or verify the
                            authenticity, the authority or the identity of such persons effecting the instructions
                            or the authenticity, accuracy, and completeness of the instruction. You must
                            immediately notify us of any unauthorized use of your Global Pinoy Travel and Tours
                            Account, password, or any other breach of security.`,
               },
               {
                  content: `We will not be liable for any loss or damage arising from your failure to comply with
                            this clause 2. To the maximum extent permitted by applicable law, we may at our
                            sole discretion refuse registration of an Global Pinoy Travel and Tours Account or
                            cancel an existing Global Pinoy Travel and Tours Account without prior notice or
                            liability.`,
               },
               {
                  content: `We also reserve the right to bar or block Users from and/or to partially or fully
                            restrict their access to, whether or not with an Global Pinoy Travel and Tours
                            Account, our Platform on a permanent or temporary basis, at our discretion. If you
                            are blocked, you agree not to attempt to use our Platform under any other name or
                            through any other User.`,
               },
            ],
         },
         {
            title: "PLATFORM CONTENT AND AVAILABILITY",
            list: [
               {
                  content: `We make no guarantees as to the authenticity, accuracy or completeness of any
                            Content on our Platform, including Content provided by other Users or our Travel
                            Suppliers. The displayed prices for our Travel Products are dynamic and can change
                            during or after your search. Once you have a Confirmed Booking, as defined further
                            below, subsequent changes in displayed prices will not affect the price of your
                            booking. All information about Travel Products on our Platform, including but not
                            limited to any ratings (aside from star ratings for Accommodations as set out in
                            clause 16), rankings and reviews, is provided to us by the corresponding Travel
                            Suppliers of such Travel Products, or their respective representatives including their
                            distributors, channel managers and partners. We also make no guarantees that the
                            Content available on our Platform has not been altered through technical defects or
                            by unauthorized third parties.`,
               },
               {
                  content: `<strong style="font-weight: 600;">All Content accessed by you using our Platform is at your own risk and you will be
                            solely responsible for any damage or loss to any party resulting therefrom. Under
                            no circumstance shall Global Pinoy Travel and Tours be liable in any way for any
                            loss or damage of any kind incurred in connection with the use of or exposure to
                            any Content accessed on our Platform</strong>, including but not limited to any errors or
                            omissions, or any additions, removals, or modifications that we make on our
                            Platform. We make no guarantee that our Platform will always be available or
                            uninterrupted. We will not be liable to you for any loss or damage resulting from any
                            unavailability or interruption of our Platform or Services.`,
               },
               {
                  content: `Our Platform does not constitute and should not be regarded as a recommendation
                            or endorsement of any Travel Product and/or Travel Supplier listed on our Platform.
                            We hereby expressly disclaim any representation, warranty or undertaking in
                            relation to the quality, status, features, or adequacy of any Travel Product and
                            Travel Supplier listed on our Platform. We may from time to time offer certain
                            programs under which Travel Suppliers may improve their Travel Product’s visibility
                            on our Platform, including but not limited to through sponsored listings. Such Travel
                            Product listings will be marked or contain wording for clarity.`,
               },
            ],
         },
         {
            title: "USER CONTENT AND REVIEWS",
            list: [
               {
                  content: `To the extent that our Platform allows you to post, upload, transmit or otherwise
                            make available any Content, including but not limited to your review of any Travel
                            Product ("<strong style="font-weight: 600">User Content</strong>), you agree that:`,
               },
               {
                  content: `You are solely responsible and liable for the User Content that you upload and you
                            represent and agree that you will not share anything that is false, misleading,
                            discriminatory or that would violate or infringe any rights of any third party,
                            including the privacy or intellectual property rights or proprietary rights;`,
               },
               {
                  content: `All User Content that you upload is not confidential or proprietary and will comply
                            with our relevant review guidelines, including Global Pinoy Travel and Tours Guest
                            Review Guidelines.`,
               },
               {
                  content: `Your User Content should not include personal information. If it does, it can only be
                            used for the purposes herein and only if such use complies with applicable data
                            protection laws in accordance with our Privacy Policy.`,
               },
               {
                  content: `We have your consent to take action against individuals and entities who breach our
                            or your rights in relation to the User Content;`,
               },
               {
                  content: `User Content does not constitute our endorsement of or affiliation with you; and`,
               },
               {
                  content: `We are under no obligation to host, store, retain, publish, share or make available
                            any User Content uploaded by you and that you will be responsible for creating
                            backups of any User Content if necessary.`,
               },
            ],
         },
         {
            title: "THIRD PARTY LINKS",
            list: [
               {
                  content: `In any case hyperlinks on this Platform to websites operated by third parties are
                            only provided for your reference. Global Pinoy Travel and Tours takes no
                            responsibility for the services provided by and/or content of these websites and has
                            no control over their content, access and privacy policies. If you click on a third party
                            hyperlink or advertisement, please be aware that you would be leaving our Platform
                            and any data you provide will be processed in accordance with said third party’s
                            respective privacy and cookie policies, which we advise you consult beforehand.
                            Global Pinoy Travel and Tours will not be responsible or liable for the consequences
                            of accessing these websites, including exposure to hard and software viruses, spy
                            and malware. We do not endorse or recommend these websites or any of their
                            contents.`,
               },
               {
                  content: `Any third party that wishes to establish links to our Platform should notify Global
                            Pinoy Travel and Tours of their intention prior to doing so. Global Pinoy Travel and
                            Tours may deny permission for any such links to our Platform. If Global Pinoy Travel
                            and Tours gives its permission for any such links, Global Pinoy Travel and Tours is not
                            under any obligation to establish reciprocal links with the third party.`,
               },
            ],
         },
      ],
   },
   {
      title: "SECTION B: GENERAL BOOKING AND PAYMENT TERMS",
      list: [
         {
            title: "GLOBAL PINOY TRAVEL AND TOURS SERVICES",
            list: [
               {
                  content: `The terms in this Section B apply to <strong style="font-weight: 600;">Global Pinoy Travel and Tours</strong> provision of its
                            Travel Product booking facilitation-related Services through the Platform. Where the
                            Travel Product booking facilitation-related Services are provided by any Partner or
                            made via a Partner’s platform, please refer to that Partner’s respective terms and
                            conditions of use and privacy policy instead as these will govern the relation
                            between you and that Partner. For the avoidance of doubt, where the Travel
                            Product is provided by a Partner, your booking through the Platform is an offer to
                            book a reservation (e.g., for Accommodations, Flights or a rental car, you are making
                            a request to book a reservation with the Partner that is subject to acceptance by the
                            Partner before it becomes a binding transaction) at the price listed on the Platform
                            for such Travel Product on such other terms and conditions notified to you by the
                            Partner at the time of booking, which shall become a binding contract when your
                            booking is accepted by the Partner and will be subject to the Partner’s terms and
                            conditions. Where applicable, the Partner will indicate the acceptance of such Travel
                            Product booking via an e-mail confirmation to you. In these cases, we will provide
                            your payment information to the relevant Partner to process your payment and
                            confirm your booking. The Partner reserves the right to reject bookings as set out in
                            their terms and conditions.`,
               },
               {
                  content: `Unless otherwise stated, all of our Travel Product booking facilitation-related
                            Services are provided by Global Pinoy Travel and Tours Inc., which is a company
                            incorporated under the laws of Philippines, having its registered address at 1472-
                            1476 Pacific Century Tower, Quezon Avenue, Barangay South Triangle, Quezon City,
                            Philippines, 1103 and is registered with the Security and Exchange Commission
                            under company registration number 2023050100893-17.`,
               },
               {
                  content: `Please note that Global Pinoy Travel and Tours does not organize any ground
                            transportation. Where ground transportation is offered, this can be organized by
                            and offered by or on behalf of the Travel Supplier or a Partner, not Global Pinoy
                            Travel and Tours. Global Pinoy Travel and Tours does not assume any liability for the
                            quality, safety, frequency, or service levels of such transportation services. Where
                            your Confirmed Booking includes a self-transfer, you are responsible for the transfer
                            and, subject to the terms of the Global Pinoy Travel and Tours (if available), Global
                            Pinoy Travel and Tours does not assume any liability in relation to the self-transfer.
                            Please verify with Customer Service which extra fees are (not) included for self-
                            transfers and ground transportation.`,
               },
            ],
         },
         {
            title: "BOOKINGS",
            list: [
               {
                  content: `When making a booking for any Travel Product through our Platform, that booking
                            is made directly with the Travel Supplier(s) named on the booking page. Our
                            Platform only acts as a facilitator of such bookings. We do not provide, own or
                            control any of the Travel Products that you can access through our Platform and we
                            have no obligation to provide or operate the Travel Products. The Travel Products
                            are owned, controlled or made available by the Travel Suppliers either directly or as
                            an agent. The Travel Suppliers are responsible for their respective Travel Products.
                            Your interaction with any Travel Supplier through our Platform is at your own risk.
                            Global Pinoy Travel and Tours does not bear any responsibility should anything go
                            wrong during your travel or your use of the Travel Product.`,
               },
               {
                  content: `A credit card, debit card, digital wallet or such other payment method as we may
                            from time to time accept (“<strong style="font-weight: 600;">Payment Instrument</strong>”) will be required in order to make
                            a booking for a Travel Product through our Platform.`,
               },
               {
                  content: `When you submit a booking through our Platform, you make an offer to book the
                            specified Travel Product(s) subject to these Terms (<strong style="font-weight: 600;">“Booking Request</strong>”). The Travel
                            Product shall be supplied by the Travel Supplier to you and the terms and conditions
                            applicable to that Travel Product shall be between you and the Travel Supplier and
                            shall be a separate binding contract pursuant to these Terms between you and the
                            relevant Travel Supplier once accepted by such Travel Supplier, communicated to
                            Global Pinoy Travel and Tours, and subsequently communicated to you via a
                            confirmation email from Global Pinoy Travel and Tours (“Confirmed Booking”). You
                            acknowledge that the “thank you page” shown after the submission of your Booking
                            Request does not indicate a Confirmed Booking and only indicates that your Booking
                            Request has been received by Global Pinoy Travel and Tours for processing.`,
               },
               {
                  content: `The relevant Travel Supplier(s) may impose additional terms and conditions, rules
                            and restrictions (each a “Travel Supplier Terms”) that will be applicable to your
                            Confirmed Booking, including in relation to changes, cancellations and refunds. You
                            will be provided with the name of each Travel Supplier and in some cases, the
                            applicable Travel Supplier Terms or a link to their Travel Supplier Terms, during the
                            booking process. As the Travel Supplier Terms applicable for each Travel Product
                            may vary, you should ensure that you fully understand and accept all of the
                            applicable Travel Supplier Terms before completing your booking. If you are booking
                            on behalf of other persons, you are required to ensure that they are aware of and
                            agree to the applicable Travel Supplier Terms. If you fail to comply with the Travel
                            Supplier Terms, the Travel Supplier has a right to refuse or cancel your booking

                            Page 7 of 17
                            without offering any refunds. For enquiries about the Travel Supplier’s products and
                            services or its Travel Supplier Terms, please contact the Travel Supplier directly.`,
               },
               {
                  content: `Except where prohibited by local law, Global Pinoy Travel and Tours has the right to
                            reject any Booking Request, and cancel any Confirmed Booking, including on behalf
                            of the Travel Supplier, or any part thereof, without reason. If you have already made
                            payment, we shall refund you the amounts paid in respect of such rejected or
                            cancelled parts in accordance with our Refund Policy, unless:`,
               },
               {
                  content: `You are in breach of these Terms or any applicable law;`,
               },
               {
                  content: `the Travel Supplier Terms do not allow for refunds; or`,
               },
               {
                  content: `Global Pinoy Travel and Tours deems in its sole discretion that you are not entitled
                            to a refund due to reasons including but not limited to suspected fraud or abuse.`,
               },
            ],
         },
         {
            title: "PRICING, ADDITIONAL CHARGES AND TAXES <br /> 8.1 Charges for Taxes and Fees",
            list: [
               {
                  content: `When browsing Travel Products on our Platform, the Display Price is generally
                             exclusive of taxes and service fees unless otherwise expressly stated.`,
               },
               {
                  content: `In connection with facilitating your booking, the charge to your Payment Instrument
                            may include an amount for charges, taxes and fees (“<strong style="font-weight: 600;">Taxes and Fees</strong>”) unless
                            indicated otherwise, which may include bank and credit card fees and charges
                            where this is available or known to us. The charge for Taxes and Fees varies based
                            on a number of factors including, without limitation, the amount Global Pinoy Travel
                            and Tours pays the Travel Supplier and the location and type of the Travel Supplier
                            and/or Travel Product and may include a fee that Global Pinoy Travel and Tours
                            retains. <br />

                            In relation to Flights or Accommodations, these Taxes and Fees may include a fee for
                            the facilitation services provided by Global Pinoy Travel and Tours in relation to your
                            Booking (“Global Pinoy Travel and Tours Booking Fee”). Except where expressly
                            prohibited by law, the Global Pinoy Travel and Tours Booking Fee is non-refundable.
                `,
               },
               {
                  content: `Taxes and Fees amount paid to the Travel Supplier on your behalf in connection with
                            your reservation may vary from the Taxes and Fees amount Global Pinoy Travel and
                            Tours estimates and includes in the charge to you. The balance of the (estimated)
                            charge for Taxes and Fees, if any, is a fee Global Pinoy Travel and Tours may retain
                            as part of the compensation: <br />
                            
                            <strong style="font-weight: 600;">c.1</strong> in relation to any Flight component of your booking, as our handling fee for the
                            services provided by Netrip to you in respect of such Flight(s);<br />

                            <strong style="font-weight: 600;">c.1</strong> in relation to any Accommodation (as applicable depending on the jurisdiction),
                            as our fee for the services provided by Global Pinoy Travel and Tours to you in
                            respect of such Accommodation.`,
               },
               {
                  content: `You are fully responsible for all taxes, charges, fees, duties, and assessments arising
                            out of the use of this Platform. While we aim to provide customers with the total
                            price of each booking on the Platform, there is a chance that a government-imposed
                            tax may be added or increased after the booking is purchased. If this occurs, by
                            accepting these Terms, you agree to pay the new or higher government-imposed
                            tax, where not prohibited by law.`,
               },
               {
                  content: `Where the Travel Product booking facilitation-related Services are provided by any
                            Partner or made via a Partner’s platform, calculation of applicable taxes and fees will
                            be set out in such Partner’s terms and conditions.`,
               },
            ],
         },
         {
            title: "PAYMENT",
            list: [
               {
                  content: `Before you submit your Booking Request, you will be notified of the payment
                            methods that are acceptable for that Booking Request.`,
               },
               {
                  content: `You authorize Global Pinoy Travel and Tours or another Partner entity to charge or
                            debit your Payment Instrument for the full booking amount once you have
                            submitted your Booking Request.`,
               },
               {
                  content: `We reserve the right to cancel your booking, including on behalf of the Travel
                            Supplier, if applicable, if the full payment for your booking is not received in a timely
                            fashion,`,
               },
               {
                  content: `including but not limited to where your Payment Instrument cannot be charged on
                            the intended Charge Date for whatever reason.`,
               },
               {
                  content: `Local tax invoices may be obtained from the Travel Supplier offering the Travel
                            Product to you. Where specifically required by law, Global Pinoy Travel and Tours
                            may provide a tax invoice to you.`,
               },
               {
                  content: `For a variety of reasons, payment on our Platform may fail. In such cases, Global
                            Pinoy Travel and Tours will use all reasonable efforts to offer you alternatives
                            (where available) to ensure your booking can go ahead or offer you a refund in
                            accordance with our Refund Policy. If you have any questions, please
                            contact Customer Service.`,
               },
            ],
         },
         {
            title: "CANCELLATION, CHANGES AND REFUNDS",
            list: [
               {
                  content: `If you wish to review, adjust or cancel your reservation, please follow the
                            instructions in the confirmation email, use our self-service tool, or contact Customer
                            Service.`,
               },
               {
                  content: `Any cancellations, changes, refunds and no-shows to your Confirmed Booking are
                            subject to the applicable booking conditions and Travel Supplier Terms, including
                            any additional fees that may be imposed by the Travel Supplier. You should read
                            our Refund Policy, the cancellation and no-show policies of the relevant Travel
                            Supplier(s), and the booking conditions for your selected reservation carefully prior
                            to making your booking. Please be advised that certain reservations may be non-
                            refundable, non-cancellable, and/or non-changeable.`,
               },
               {
                  content: `Without prejudice to clause 10.b, Global Pinoy Travel and Tours reserves the right to
                            charge a cancellation and/or modification fee on behalf of the Travel Supplier, up to
                            the full cost of the reservation, for any cancellation or modification by you of a
                            Confirmed Booking, and such fees will be indicated on our Platform.`,
               },
            ],
         },
         {
            title: "YOUR OBLIGATION, REPRESENTATIONS AND WARRANTIES",
            list: [
               {
                  content: `When you submit a Booking Request, you represent and warrant to Global Pinoy
                            Travel and Tours that:`,
               },
               {
                  content: `you are 18 years of age or older (or such other age at which majority is reached in
                            your country or territory) and have the full legal capacity to make the transaction (or
                            have the authorization to do so from your legal guardian);`,
               },
               {
                  content: `the Payment Instrument you are using to make the booking is your own or that you
                            are authorized to complete the booking with it, and that there are sufficient funds to
                            cover the cost of the transaction;`,
               },
               {
                  content: `the payment details you provide us with in making a booking are fully correct;`,
               },
               {
                  content: `you are not subject to any international sanctions program or restrictions;`,
               },
               {
                  content: `if and when applicable, you will satisfy all your obligations, tax, withholding or
                            otherwise, as required by applicable law.`,
               },
               {
                  content: `You also undertake to provide correct and accurate e-mail, postal and/or other
                            contact details to Global Pinoy Travel and Tours and acknowledge that Global Pinoy
                            Travel and Tours may use these details to contact you in the event that this should
                            prove necessary in connection with your booking or other use of our Platform.`,
               },
            ],
         },
         {
            title: "PROMOTIONS",
            list: [
               {
                  content: `Global Pinoy Travel and Tours may support promotional rates, vouchers, discounts,
                            promo codes and special offers (“<strong style="font-weight: 600;">Promotions</strong>”) from time to time. Unless expressly
                            stated otherwise, Promotions may not be combined with other Promotions and/or
                            loyalty programs. Promotions are subject to further rules and special conditions
                            which will be posted on our Platform, as may be amended by Global Pinoy Travel
                            and Tours from time to time. Promotions cannot be redeemed, exchanged or
                            refunded for cash, credit, or other products. Some Promotions may only be available
                            in certain territories or for residents of certain territories and may exclude certain
                            types of Travel Products and may be subject to additional terms and conditions.`,
               },
               {
                  content: `Where you have a promo code, or voucher, if it is not automatically applied, you
                            must apply it at the payment page in order to apply it to your booking. Unless
                            expressly stated otherwise, Promotions will not apply to the local taxes, service fees
                            and additional charges of your booking.`,
               },
               {
                  content: `Global Pinoy Travel and Tours reserves the right to stop the use of any Promotion for
                            any reason. In case of any dispute relating to Promotions, Global Pinoy Travel and
                            Tours’s decision shall be final.`,
               },
            ],
         },
         {
            title: "FRAUD",
            list: [
               {
                  content: `We have stringent fraud detection and prevention mechanisms in place. In certain
                            cases, we may require additional information or verifications to validate and confirm
                            your booking. If you choose not to submit the additional information or if we are not
                            satisfied with the information received, your Booking Request will be cancelled
                            because it is incomplete, and any amounts charged will be refunded. It is possible
                            that a Travel Product becomes unavailable during the fraud check, in which case the
                            booking will no longer be available. You acknowledge and agree that Global Pinoy
                            Travel and Tours shall not be held liable in such circumstances.`,
               },
               {
                  content: `If you suspect an unauthorized or fraudulent booking was made using your Payment
                    Instrument via Global Pinoy Travel and Tours, please contact Customer
                    Service immediately. You should also contact your bank or Payment Instrument
                    issuer immediately upon becoming aware of such unauthorized use.`,
               },
            ],
         },
         {
            title: "TRAVEL ADVISORY AND VISA REQUIREMENTS",
            list: [
               {
                  content: `It is your responsibility to ensure that you satisfy all applicable legal requirements
                            when entering a country or territory, including its visa and health requirements. As
                            these requirements are subject to change without notice, Global Pinoy Travel and
                            Tours recommends that you review travel prohibitions, warnings, announcements,
                            and advisories and verify with the appropriate consulate prior to booking and again
                            before departure. It is your responsibility to ensure that you are either insured by
                            your own insurance company, or by the Travel Suppliers who may provide insurance
                            coverage. Global Pinoy Travel and Tours takes no responsibility in the insurance

                            Page 10 of 17
                            process and recommends that you are sufficiently covered with a comprehensive
                            travel insurance policy prior to departure.`,
               },
               {
                  content: `By displaying Travel Products in or to particular destinations, Global Pinoy Travel and
                            Tours does not represent or warrant that travel to such points is advisable or risk-
                            free and Global Pinoy Travel and Tours shall not be held liable for damages or losses
                            that may result from travel to such destinations. You are strongly advised to check
                            the relevant travel advisories issued by your government for any country you are
                            visiting and to take the necessary precautions.`,
               },
            ],
         },
      ],
   },
   {
      title: "SECTION C: ACCOMODATIONS",
      list: [
         {
            title: "ADDITIONAL PAYMENT OPTIONS",
            list: [
               {
                  content: `in some cases, a Travel Supplier may pre-authorize your Payment Instrument and
                            charge a deposit or charge the full booking amount up-front; and`,
               },
               {
                  content: `in some cases, a Travel Supplier may require your Payment Instrument details in
                            order to guarantee your booking and you hereby authorize Global Pinoy Travel and
                            Tours to send such information to that Travel Supplier in accordance with
                            our Privacy Policy.`,
               },
               {
                  content: `To the extent your booking includes Accommodations, depending on your location,
                            you may be given the option to make payment of your booking amount in
                            instalments (“Instalments” or “Deferred Payments”). If you have selected this
                            option: <br /><br />

                            <strong style="font-weight: 600;">c.1</strong> you acknowledge and agree that this payment option is not provided by Global
                            Pinoy Travel and Tours but by a third party (“Instalment Facilitator”) and subject to
                            additional terms and conditions, including such terms and conditions as may be
                            imposed by that Instalment Facilitator. You may be redirected to the Instalment
                            Facilitator’s environment to complete the instalment payment option and accept
                            Instalment-specific terms and conditions. If you do not meet the Instalment
                            Facilitator’s terms it is at their absolute discretion to not make the instalment
                            payment option available to you; <br /><br />

                            <strong style="font-weight: 600;">c.2</strong> if you are in breach of the Instalment Facilitator’s terms, you will be subject to
                            the consequences stipulated by the Instalment Facilitator’s terms. Global Pinoy
                            Travel and Tours has no liability to you for any such consequences under these
                            terms; and <br /> <br />
                            
                            <strong style="font-weight: 600;">c.3</strong> Global Pinoy Travel and Tours reserves the right to cancel your booking,
                            including on behalf of the Travel Supplier, if you breach the Instalment Facilitator’s
                            terms and you agree to pay and be solely liable for the full booking amount. You
                            further agree that Global Pinoy Travel and Tours has the right to assign the full
                            booking amount payable by you to the Instalment Facilitator and/or its factors.`,
               },
            ],
         },
         {
            title: "STAR RATINGS",
            list: [
               {
                  content: `Star or other denoted ratings (<strong style="font-weight: 600;">star ratings</strong>) may be displayed for some
                            Accommodations on our Platform. Such star ratings are based on information we
                            have received from the relevant Travel Suppliers and are provided for your
                            information only. Global Pinoy Travel and Tours does not independently verify such
                            star ratings and makes no representation that these ratings are or will be consistent
                            with ratings on other websites and platforms. Without prejudice to the generality of
                            clause 3.a, Global Pinoy Travel and Tours makes no guarantees or representations

                            Page 11 of 17
                            about the facilities, amenities, services, quality, comfort or any other features of any
                            Accommodation based on the star ratings.`,
               },
            ],
         },
         {
            title: "BEST PRICE GUARANTEE PROGRAM",
            list: [
               {
                  content: `We strive to have the most competitive prices and we want you to pay the lowest
                            price possible for your stay at an Accommodation.`,
               },
            ],
         },
         {
            title: "PRICE FREEZE",
            list: [
               {
                  content: `To the extent that your booking relates to Accommodations, you may be permitted
                            to purchase “<strong style="font-weight: 600;">Price Freeze Services</strong>”, which enables you to freeze a selected
                            Accommodation stay: (i) at a specified price, and (ii) for a specified period of time
                            and later return to complete your booking, subject to availability and other terms
                            and conditions stated hereinafter. The frozen price amount is exclusive of taxes and
                            fees, which will be an additional amount charged at the time of booking.`,
               },
            ],
         },
      ],
   },
   {
      title: "SECTION D: FLIGHTS",
      list: [
         {
            title: "RESCHEDULING AND CHANGES",
            list: [
               {
                  content: `To the extent that your Confirmed Booking includes any Flight(s), you acknowledge
                            that the Travel Supplier and/or relevant airline or airlines may reschedule or cancel
                            such Flights in accordance with the Travel Supplier Terms. Global Pinoy Travel and
                            Tours is not responsible for changes to the scheduled operation of the Flights,
                            including delayed or cancelled Flights, and any failure by the Travel Supplier to
                            provide you with notice of changes to the Flights.`,
               },
               {
                  content: `If you wish to modify or cancel any Flight component of your Confirmed Booking,
                            you acknowledge that in accordance with clause 10.c above, Global Pinoy Travel and
                            Tours will impose an additional charge per person for each modification, except
                            where prohibited by local law, and this is in addition to any applicable charges by
                            Travel Suppliers and airlines. While these charges are additional, they are part of the
                            compensation for our Services for Flights bookings.`,
               },
            ],
         },
         {
            title: "FARES WITH MULTIPLE AIRLINES",
            list: [
               {
                  content: `We may offer fares that are a combination of one-way and/or return Flight
                            segments that could involve different airlines. In processing your combined Flight
                            booking, it is possible that certain Flight segments will be confirmed and other Flight
                            segments for the same journey cannot be booked (or may be cancelled or
                            significantly changed after booking). You acknowledge that each Flight segment in a
                            combined journey is a separate flight booking. You will not be allowed to modify or
                            cancel the confirmed Flight segments for free, even if other Flight segments for the
                            same journey are not successfully booked. All Flight bookings are subject to terms
                            and conditions that may vary based on the travel origin/destination, including
                            policies on Flight booking changes and fees, cancellations, and refunds for individual
                            or combined Flights/segments. Changes to one Flight may not automatically be
                            applied to the other Flight segments, which may need to be changed individually.
                            This may result in separate fees and charges to change each segment, including the
                            Global Pinoy Travel and Tours Booking Fees. You may require evidence of a return or
                            onward travel for international flights. If the bookings of all Flight segments will be
                            confirmed, you will receive a single booking confirmation for the whole journey. The
                            fee Global Pinoy Travel and Tours retains as part of the compensation for its Services

                            Page 12 of 17
                            and to cover the costs of booking or facilitating your reservation, is calculated based
                            on the whole journey at the time of booking.`,
               },
            ],
         },
         {
            title: "AIRLINE AND TICKET CONDITIONS",
            list: [
               {
                  content: `Without prejudice to the generality of clause 7.d above, you agree to be bound by
                            the terms and conditions of carriage of the airline(s) issuing the tickets for any Flight
                            component of your Confirmed Booking. Such terms and conditions may include
                            restrictions in relation to payment, refunds, cancellation, reservation modification,
                            scheduled times and dates, liability, and fare restrictions and conditions. There may
                            also be prohibitions on &quot;point-beyond&quot;, &quot;hidden-city&quot;, or &quot;back-to-back&quot; tickets and
                            other bookings which include segments which you do not intend to use, on use of
                            flight coupons out of sequence, and on buying a return ticket when you only intend
                            to travel one-way. You understand that any violation of any terms and conditions of
                            the airline, including purchase conditions, could result in cancellation of your
                            Flight(s), being denied access to your Flight(s), or forfeiting any payments made for
                            Flight(s). We may also charge you for any costs and penalties incurred as a result of
                            such violation. We recommend that you read the refund, cancellation and no-show
                            policies applicable to your Flight(s) carefully prior to making your booking, including
                            where the airline changes or cancels your Flight booking.`,
               },
               {
                  content: `Sometimes, cheaper fares are available on the Platform for the same Flight, for the
                            same Flight segment(s) on the same date, at the same time and on the same airline,
                            however, these cheaper fares may carry special restrictions and conditions, for
                            example in respect of cancellation and refund. Please check the fare and rate details
                            thoroughly for any such conditions prior to making your booking. Different tickets on
                            the same airline for the same Flight may carry different restrictions or include
                            different services.`,
               },
               {
                  content: `Airlines may require you to pay additional fees at the airport for certain services
                            and/or if you exceed certain limits as dictated by the airline, including free or
                            included pieces or weight of your baggage, seat selection, meals and priority
                            boarding. While we provide some details of what is included in your ticket for your
                            Flight, this is not a substitute for, or summary of, the airline terms and conditions
                            and you should ensure to review these separately.`,
               },
            ],
         },
         {
            title: "FLIGHT PRICE",
            list: [
               {
                  content: `Please note that all prices for Flights on the Platform are for your Flight reservation
                            only and are for the requested flights and for the number of people indicated, unless
                            indicated otherwise. The Flight reservations rates are displayed per person. We may
                            also charge booking and convenience fees for certain types of bookings during or
                            after the Booking is made. These will be displayed in the booking form and on the
                            confirmation screen as an Global Pinoy Travel and Tours Booking Fee and an Global
                            Pinoy Travel and Tours Convenience Fee, and Miscellaneous.`,
               },
            ],
         },
      ],
   },
   {
      title: "SECTION E: COMBINATION",
      list: [
         {
            title: "SPECIFIC COMBINATION TERMS",
            list: [
               {
                  content: `The total Display Price for any Combination is based on booking and paying for the
                            component of the Travel Products (e.g., the Accommodation(s) and the Flight(s) as a
                            combined package). You acknowledge that the Display Price may not be the same as
                            booking and paying for each component of the Combination separately.`,
               },
               {
                  content: `If the terms and conditions of your Combination permit you to change or cancel part
                            of your Combination and you do so, your booking may then cease to constitute a
                            Combination and the benefits and savings achieved by booking a Combination may
                            no longer apply or be available.`,
               },
               {
                  content: `Notwithstanding clause 7.c above, where your Booking Request relates to a
                            Combination, each Travel Supplier involved must accept your Booking Request in
                            order for you to receive a Confirmed Booking, and a separate contract is formed
                            between you and each Travel Supplier involved for the respective Travel Product
                            provided by that Travel Supplier.`,
               },
               {
                  content: `Further to clause 10 above, where you have booked a Combination that comprises
                            two or more Travel Products, changes to or cancellations of one Travel Product will
                            not automatically be applied to the other Travel Product(s). You are responsible for
                            making any consequential changes and/or cancellations and any fees and charges
                            incurred in doing so.`,
               },
            ],
         },
      ],
   },
   {
      title: "SECTION G: GENERAL TERMS",
      list: [
         {
            title: "DATA PROTECTION AND PRIVACY",
            list: [
               {
                  content: `For information regarding our treatment of your personal data, please review our
                            current Privacy Policy, which is incorporated by reference into these Terms. To the
                            extent required under applicable law, your acceptance of these Terms constitutes
                            acceptance and agreement to our collection, use and disclosure of your personal
                            data in accordance with our Privacy Policy.`,
               },
            ],
         },
         {
            title: "INTELLECTUAL PROPERTY",
            list: [
               {
                  content: `Unless otherwise stated, all rights in our Platform (including the intellectual
                            property, technology, trademarks, arrangement and compilation of the Content and
                            the infrastructure used to provide such Content, etc.) are owned by or licensed to
                            Global Pinoy Travel and Tours. You will abide by all copyright notices, trademark
                            rules, information, and restrictions contained in any Content accessed through our
                            Platform. You will not acquire any right, title or interest in or to the intellectual
                            property rights except for the limited license to use our Platform and/or our Services
                            granted to you pursuant to these Terms. Global Pinoy Travel and Tours’s trade or
                            service marks may not be copied, scraped, imitated or used, in whole or in part,
                            without prior written permission.`,
               },
               {
                  content: `Other company, product, and service names and logos used and displayed via our
                            Platform may be trademarks or service marks of their respective owners who may or
                            may not be endorsed by, or affiliated with, or connected to Global Pinoy Travel and
                            Tours.`,
               },
            ],
         },
         {
            title: "INDEMNITY",
            list: [
               {
                  content: `To the maximum extent permitted by applicable law, you agree to indemnify,
                    defend and hold harmless the Global Pinoy Travel and Tours Group, their
                    subsidiaries and other affiliates, and each of their respective partners, officers,
                    directors, employees, representatives, distributors, suppliers, licensors, agents or
                    others involved in creating, sponsoring, promoting, or otherwise making available
                    the Platform and its contents, payment service providers and other applicable third
                    parties (“Covered Party(ies)”) from and against any liability, damage or loss
                    including legal fees and expenses in excess of the limits of liability set out in clause

                    Page 14 of 17
                    28, arising out of or related to your (or any other person using your Global Pinoy
                    Travel and Tours Account’s):`,
               },
            ],
         },
         {
            title: "DISCLAIMERS &amp; LIMITATIONS OF LIABILITY",
            list: [
               {
                  content: `Global Pinoy Travel and Tours has no special relationship with or fiduciary duty to
                            you for accessing and using our Platform, Content and Services. We have no control
                            over, and no duty to take any action regarding: <br />
                            
                            <br />
                            <strong style="font-weight: 600;">a.1</strong> which Users gain access to our Platform;
                            <br />
                            <br />
                            <strong style="font-weight: 600;">a.2</strong> what Content you access via our Platform;
                            <br />
                            <br />
                            <strong style="font-weight: 600;">a.3</strong> what effects our Content may have on you;
                            <br />
                            <br />
                            <strong style="font-weight: 600;">a.4</strong> how you may interpret or use our Content; and
                            <br />
                            <br />
                            <strong style="font-weight: 600;">a.5</strong> what actions you may take as a result of having been exposed to the Content.
                            `,
               },
               {
                  content: `We are also not liable for any costs associated with your network provider, who may
                            charge you for accessing their connection services to access and use our Platform.
                            We are not responsible for the availability and quality of your telecommunication
                            reception when accessing or using our Platform or Services.`,
               },
               {
                  content: `The Platform, including all Services and Content therein, are provided on an &quot;as is&quot;
                            and &quot;as available&quot; basis. We disclaim any and all warranties (express, implied or
                            otherwise), including without limitation warranties of suitability, merchantability,
                            safety, non-infringement, fitness for a particular purpose, and that our Platform is
                            free from interruptions, errors or other inaccuracies, whether arising by a course of
                            dealing, usage, trade practice or course of performance, or by using services of
                            generative AI algorithms or similar technology.`,
               },
               {
                  content: `We do not warrant or make any representations regarding: (i) the acceptance of any
                            request; (ii) the availability of any Travel Product through the Platform; (iii) the
                            results that may be obtained from the use of the Platform; or (iv) that you will
                            receive the lowest available price for any Travel Product available through the
                            Platform.`,
               },
               {
                  content: `Without prejudice to the generality of this clause 27 and to the maximum extent
                            permitted under applicable law, Global Pinoy Travel and Tours disclaims all liability
                            arising out of or in connection with: <br />
                            
                            <br />
                            <br />
                            <strong style="font-weight: 600;">e.1</strong> errors or inaccuracies on our Platform, including without limitation errors in
                            descriptions, pricing, booking availability, photographs, features, inclusions and
                            exclusions, reviews and ratings and Global Pinoy Travel and Tours reserves the right
                            to correct any errors in booking availability and errors in pricing on our Platform at
                            all times;
                            
                            <br />
                            <br />
                            <strong style="font-weight: 600;">e.2</strong> any disruptions to our Platform and the services offered on and through it; and
                            
                            <br />
                            <br />
                            <strong style="font-weight: 600;">e.3</strong> any viruses or other harmful components contained in email that we may send.
                                
                            `,
               },
               {
                  content: `To the extent permitted by applicable law, in no event shall any Covered Party be
                            liable for any direct, special, indirect, incidental, exemplary, compensatory, punitive
                            or consequential loss or damage of any kind, including but not limited to: <br />
                            
                            <br />
                            <br />
                              <strong style="font-weight: 600;">f.1</strong> loss of actual or anticipated income (whether direct or indirect);

                            <br />
                            <br />
                              <strong style="font-weight: 600;">f.2</strong> loss of actual or anticipated profits (whether direct or indirect); or
                            
                            <br />
                            <br />
                            <strong style="font-weight: 600;">f.3</strong> loss of contracts or business (whether direct or indirect),

                            <br />
                            <br />
                            howsoever arising under or in connection with these Terms, even if we have been
                            advised of the possibility of such damages. This provision applies regardless of whether
                            the damages are based in contract, tort (including negligence), breach of statutory duty
                            or otherwise, including under the indemnity obligations under these Terms.
                            `,
               },
               {
                  content: `You acknowledge and agree that the Global Pinoy Travel and Tours does not in any
                            way directly or indirectly operate or control any Travel Product provided by any
                            Travel Supplier or other third party, and that we have no control over or
                            responsibility whatsoever for any arrangements you make with them as a result of
                            your use of our Platform or Services. You also acknowledge and agree that if there is
                            a dispute between you and any Travel Supplier or other third party, Global Pinoy
                            Travel and Tours is under no obligation to become involved. Global Pinoy Travel and
                            Tours disclaims all liability arising out of or in connection with any loss or damage
                            you incur with any Travel Supplier or other third party. In the event of non-
                            performance of any Confirmed Booking, including from bankruptcy, rehabilitation,
                            reorganization, insolvency, dissolution or liquidation of a Partner and/or Travel
                            Supplier, you agree that your only recourse will be against such Partner and/or
                            Travel Supplier and not against us.`,
               },
               {
                  content: `If we are found liable for any loss or damage that arises out of or is in any way
                            connected with your use of our Platform or Services, or your booking or use of
                            products or services made available through our Platform, then the liabilities of the
                            Global Pinoy Travel and Tours in the aggregate will not exceed the lower of (a) the
                            aggregate cost of your purchased Travel Product as set out in the confirmation email
                            (whether for one event or a series of connected events)`,
               },
               {
                  content: `In case of an overcharge or mischarge caused by Global Pinoy Travel and Tours, you
                            can make a claim regarding all erroneous charges notwithstanding what is set out
                            above. Claims should be submitted as soon as possible after occurrence of the event
                            giving rise to the claim. Claims submitted with undue delay may be considered void
                            pursuant to the applicable statute of limitations.`,
               },
               {
                  content: `Nothing in this clause 27 shall limit or exclude any liability that cannot be limited or
                            excluded under applicable law and Global Pinoy Travel and Tours shall bear the
                            liability to the extent set forth in the relevant laws.`,
               },
               {
                  content: `You agree that the above limitations of liability reflect the allocation of risk between
                            you and Global Pinoy Travel and Tours and that, in the event that any of the
                            remedies set out in these Terms are in breach of applicable law, these limitations of
                            liability will survive.`,
               },
            ],
         },
         {
            title: "EVENTS BEYOND OUR CONTROL",
            list: [
               {
                  content: `We shall have no liability to you for any breach of these Terms caused by any event
                            or circumstance beyond our and/or the Covered Parties’ control including, but not
                            limited to, natural disasters, weather conditions, fire, nuclear incident, electro-
                            magnetic pulse, terrorist act, riots, war, arson attacks, insurgency, rebellion, armed
                            hostilities of any kind, labour disputes, lockouts, strikes, shortages, government
                            actions or restraints, pilferage, bankruptcy, machinery breakdown, network or
                            system interruptions or breakdown, internet or communications breakdown,
                            quarantine, epidemic, or pandemic.`,
               },
            ],
         },
         {
            title: "MISCELLANEOUS",
            list: [
               {
                  content: `Global Pinoy Travel and Tours reserves the right, in its sole discretion, to the extent
                            permissible under relevant law to temporarily or permanently modify, suspend, or
                            terminate the Platform and/or any part thereof. If our Platform is terminated, your
                            Confirmed Bookings will still be honoured by the Travel Supplier(s), subject to their
                            terms and conditions, unless you receive a notification indicating otherwise. You will
                            still be bound by your obligations under these Terms, including the warranties made
                            by you, and by the disclaimers and limitations of liability. Additionally, Global Pinoy
                            Travel and Tours shall not be liable to you or any third-party for any termination of
                            your access to the Platform.`,
               },
               {
                  content: `Global Pinoy Travel and Tours do not act as a travel agent in relation to the Services
                            and any Travel Product, except as otherwise expressly stated on our Platform.`,
               },
               {
                  content: `These Terms and any other documents expressly referred to in these Terms, as may
                            be amended from time to time, constitute the entire agreement and understanding
                            between Global Pinoy Travel and Tours and you in relation to the subject matter of
                            these Terms and supersedes any previous agreement or understanding between
                            Global Pinoy Travel and Tours and yourself in relation to such subject matter.`,
               },
               {
                  content: `We may amend these Terms at any time. Such amendments shall be effective once
                            they are posted on the Platform. It is your responsibility to review the Terms
                            regularly. Your continued use of the Platform after any such amendments, whether
                            or not reviewed by you, constitute your agreement to be bound by such
                            amendments. Each of your bookings will be governed by the then-current applicable
                            Terms at the time of such booking.`,
               },
               {
                  content: `Where any or part of these Terms is deemed to be invalid, void, illegal or
                        unenforceable, the legality, validity and enforceability of the remaining parts of
                        these Terms shall not be affected or impaired thereby and shall continue in force as
                        if such invalid, void, illegal or unenforceable part of these Terms was severed from
                        these Terms.`,
               },
               {
                  content: `For the avoidance of doubt, these Terms do not apply to commercial contractual
                            relationships that Global Pinoy Travel and Tours and its partner entities may have
                            with others, including but not limited to the Travel Suppliers and certain marketing
                            partners.`,
               },
            ],
         },
         {
            title: "INFORMATION, QUESTIONS AND COMPLAINTS",
            list: [
               {
                  content: `For additional information and to learn more about Global Pinoy Travel and Tours, please
                            refer to our FAQs on the Platform.`,
               },
               {
                  content: `If you have complaints about our Services, you can contact our Customer Service. We will do
                            our very best to help you. Please note that any complaints about Travel Products themselves
                            are ultimately a matter for the Travel Supplier. Global Pinoy Travel and Tours may
                            coordinate directly with a Travel Supplier to try and find a mutually agreeable solution for
                            you but cannot guarantee a satisfactory outcome.`,
               },
            ],
         },
      ],
   },
   {
      title: "SECTION H: HOW WE WORK",
      list: [
         {
            title: "30. RANKING AND SPONSORED LISTINGS FOR ACCOMODATIONS",
            list: [
               {
                  content: `The default ranking for Accommodations is based on an automated algorithm that
                            may be subject to periodic updates or amendments. This ranking considers multiple
                            factors that include, but are not limited to, the following: <br />

                            <br/>
                            • Availability

                            <br/>
                            • Promotions and rates

                            <br/>
                            • Historical popularity
                            
                            <br/>
                            • Conversion (the ratio of bookings to Platform visits)
                            
                            <br/>
                            • Sales of room nights
                            
                            <br/>
                            • Cancellations
                            
                            <br/>
                            • Quality of content
                            
                            <br/>
                            • Guest review scores
                            
                            <br/>
                            • Star ratings
                            

                            `,
               },
            ],
         },
         {
            title: "31. ABOUT OUR REVIEWS",
            list: [
               {
                  content: `Only certified guests who have booked through Global Pinoy Travel and Tours and
                            not cancelled their booking are invited by Global Pinoy Travel and Tours to submit a
                            review.`,
               },
               {
                  content: `Whether negative or positive, we will post every comment in full, and as quickly as
                            possible, after being moderated to comply with the our Guidelines. Some reviews
                            may be temporarily truncated for the sake of brevity, but anyone on our Platform
                            can still access the entire review. Reviews may also sometimes be summarized for
                            the sake of brevity and readability. We will also provide transparency over the status
                            of submitted content.`,
               },
               {
                  content: `To keep the rating score and review content relevant, we may only accept reviews
                            that are submitted within 12 months of checking out and we may stop showing
                            reviews older than up to 36 months, or if the Accommodation has a change of
                            ownership.`,
               },
               {
                  content: `If we find any fake reviews, we delete them and, if necessary, act against whoever is
                            responsible. For example, if a complaint is raised against a particular review, we look
                            at the review in question and may decide it is inappropriate if it does not meet our
                            review guidelines or appears not to be a genuine review.`,
               },
               {
                  content: `The opinions expressed in contributions are those of Global Pinoy Travel and Tours
                            customers and properties and not of Global Pinoy Travel and Tours. Global Pinoy
                            Travel and Tours does not accept responsibility or liability for any reviews or
                            responses. Global Pinoy Travel and Tours is a conduit (without any obligation to
                            verify) and not a publisher of these comments and responses.`,
               },
               {
                  content: `At Global Pinoy Travel and Tours, we believe real reviews from fellow travelers
                            provide some of the best information to help you and others make future bookings.`,
               },
            ],
         },
         {
            title: "USE OF ALGORITHMS AND MACHINE LEARNING",
            list: [
               {
                  content: `We may deploy tools in providing and seeking to improve and develop our services
                            that utilize algorithms and machine learning (including tools operating on a
                            predictive basis and tools deploying generative AI algorithms to create new
                            content). These tools may interact with you (e.g., we may use chatbots or similar
                            technology). We consider where and how to deploy these tools in line with
                            applicable laws. In deploying such tools, we also have regard to our Privacy
                            Policy, the level of human intervention or oversight we apply, further disclosures to
                            explain the deployment and other relevant matters for the contemplated
                            deployment.`,
               },
            ],
         },
      ],
   },
];

// PRIVACY_POLICY_DATA

export const PRIVACY_POLICY_DATA = [
   {
      content: [
         `At Global Pinoy Travel & Tours, we are committed to protecting
          your privacy and ensuring that your personal information is
          treated with the utmost care and responsibility.`,

         `This Privacy Policy describes how Global Pinoy Travel & Tours –
           GPRS Inc. (Global Pinoy Travel & Tours, we, us, our) collects,
           uses, and shares information about you when you visit and
           interact on our website (via mobile and desktop) and mobile app
           (collectively, “Site”) or other means connected to the Sites
           (such as booking from our service/s or connecting with our
           customer service team).`,

         `The following are our Privacy Policy practices. You may also
           download it by clicking the download button beside:`,
      ],
   },
   {
      title: "1. THE INFORMATION WE COLLECT",
      content: [
         // Arrays are bulleted
         [
            `Hotel Booking/Reservation – accommodation name,
             age, location and/or length of stay.`,

            `Flight Booking – Passenger name, date of birth, nationality
             and passport details including passport number, country of
             issue and expiry.`,

            `Payment Gateway – Card holder name, card number and other details that the payment gateway provider might need.
             We guarantee that all information is secured and encrypted and passed only to the payment gateway provider.`,
         ],
      ],
   },
   {
      content: [
         `By visiting our Site, we may automatically collect certain usage information, such as your IP address,
          geographic data, country, search preferences related to specific searches, browser, and information about
          your device’s operating system, application version, language settings, and pages that have been shown to you.
          If you are using a mobile device to access our mobile app, we might also collect information that identifies your mobile device,
          such as the device ID, location of the device (with your consent), and device-specific settings and characteristics.`,

         `We will also collect information from you when connecting to our customer support team, or through other means such as
          (social media and through our Site).`,

         `You may participate in our referral programs or promotions, with this, we will also collect personal information from you.
          In addition, you can provide us with feedback or ask for help with using our Site.`,

         `Where you are making a booking with other guests whose details you provide to us as part of your reservation,
          or if you make a booking on behalf of someone else, it is your responsibility to ensure that the person or people you have
          provided personal data about are aware that you have done so, and have understood and accepted how Global Pinoy Travel & Tours
          uses their information (as described in this Privacy Policy). In case of disputes arises or if you have any questions regarding
          your booking, we may also share your information with our booking suppliers/partners.`,
      ],
   },
   {
      title: "2.	HOW WE USE THE INFORMATION WE COLLECT",
      content: [
         `We may use information collected through our Site to:`,

         [
            `Provide you your requested services from us, such as creating a user account, processing and confirming bookings,
             and communicating with you about the requested services such as (pre-booking or post booking, special requests, cancellation and/or rewards).`,

            `Send you marketing tools and communications regarding our products, services, news, offers and promotions that may be relevant to you. `,

            `Conduct surveys or provide you with information about our services on our Site.`,

            `Maintain and improve our Site, tailor the user experience (including the provision of the advertisements about the products and services of Global Pinoy Travel & Tours)
             and for internal trainings.`,

            `Protect your security and our Site.`,

            `Exercise the right and/or obligation conferred or imposed by the law, including responding to requests and legal demands.`,

            `You will receive a confirmation emails and text messages right after your Booking, but please note that these are not marketing messages/promotions. Rather,
             these are part of your booking processes. Respectively, they contain information for you that you may 
             use for your check in/boarding at your booked accommodation/flight. `,
         ],
      ],
   },
   {
      title: "3. Mobile Devices",
      content: [
         `By allowing us, we may send you push notifications containing your information about your requested service/s, guest reviews, potential future stays,
          or notify you with any of the Global Pinoy Travel & Tours programs and promotions that you are qualifying at, including the coupons.
          You may also grant us an access to your location information or contact details in order to provide you our service/s.`,
      ],
   },
   {
      title: "4.	SHARING YOUR INFORMATION",
      content: [
         `In connection with your visit to our Site and use of our Service/s, we may share your information with the following:`,
         [
            `Suppliers, who will provide you your accommodation, flights and travel and tour packages. These suppliers may contact you as necessary to obtain additional information about you,
             facilitate your accommodation, flights and travel and tours bookings/reservations. Including communicating with you prior to your arrival to your accommodation or flight,
             or respond to your reviews in accordance with their own privacy policies.`,

            `Service Providers, who will provide/facilitate you with your payments through the payment gateway process. These providers might need your payment details or card details,
             depending on their requirements for you to successfully pay your bookings to us.`,

            `Business Partners, with whom we may jointly offer our products and/or services, or whose products or services may be offered through our Sites.`,

            `Affiliates, who have access to this information with our permission and who needs to know this information in order to give you our 
             full and excellent service (including the creation, administration, and the management of bookings and the handling of payments,
             and customer service).`,

            `We provide appropriate protections for such sharing as required by the applicable law to prohibit our partners from using your information for their own purposes,
             and to address the security and confidentiality of your information. Except, as disclosed in this Privacy Policy or as required or permitted by the applicable law,
             we will not disclose your information to our partners without your consent.`,
         ],
      ],
   },
   {
      title: "5. Cookies",
      content: [
         `Our Site may use and allow Suppliers to place cookies, application programming interfaces (API’s) and other technologies on our Site that may collect and store certain information about you.
          Some of these cookies are needed to provide, secure, and maintain the basic functions of our Site, such as to keep you logged in `,

         "while your visit to our Site (Functional Cookies), while the other Cookies are used to provide you with a better user experience such as:",

         [
            `To personalize our Site by remembering your activities on our Site, such as language you selected and your log-in details/credentials.
             We may also use Cookies together with the other similar technologies that can automatically collect your usage information to recognize you across different devices,
             sessions or browsers so as to deliver tailored information.`,

            `To provide you advertisements tailored to your interests and preferences. (Non-Functional Cookies).`,
         ],
      ],
   },
   {
      title: "6. INFORMATION FROM OTHER PARTNERS",
      content: [
         `Our affiliates, business partners and/or other third party providers may share information with us.
          For example, your credit card issuer might, through Visa or Mastercard, provide us with your new card/payment details,
          when the details we have in database are already outdated. In general,
          your ability to provide such information can be through the service provider themselves and you can update those settings in your account settings of the relevant service provider.
          In general, we may work with our partners to improve and personalize your use of our Website in accordance with this Privacy Policy.`,
      ],
   },
   {
      title: "7. SECURITY",
      content: [
         `We guarantee that we handle your personal information adhering to established security standards and procedures.
          We ensure that access to personal information is restricted. We maintain technology products and systems to prevent unauthorized computer access.
          We securely dispose of or delete your personal information when there is no longer business or legal reason to keep the same.`,
      ],
   },
   {
      title: "8. INFORMATION STORAGE",
      content: [
         `We guarantee that all the information we collected from you are securely stored in our respective system database only and access to this repository is very restricted.
          Any unauthorized access shall be detected and reported promptly by our system.`,
      ],
   },
   {
      title: "9. UPDATES TO PRIVACY POLICY",
      content: [
         `We shall regularly review the sufficiency of this Privacy Policy. We reserve the right to modify and change the Privacy Policy at any time.
          Any changes to this policy shall be published accordingly.We encourage you to periodically review this policy to be informed of how we are using and protecting your personal information.`,
      ],
   },
   {
      title: "10.	MINORS",
      content: [
         `Availment of access to our Site is not intended for children, or those below eighteen (18) years of age or older but are incapable of taking care of themselves as defined under Philippine laws,
          but we may ask certain information about children from their parents or legal guardians to process our bookings.`,
      ],
   },
   {
      title: "11.	GOVERNING LAW",
      content: [
         `This Privacy Policy is governed by the laws of the Republic of the Philippines. Any disputes arising under or in connection with this Privacy Policy shall be resolved in accordance with these laws.`,
      ],
   },
];
