import "./contact-us.css";

const ContactUsPage = () => {
   return (
      <div className="main-container">
         <div className="title-container">
            <h1 className="page-title">Contact Us</h1>
            <p className="page-subtitle">
               Any question or remarks? Just write us a message!
            </p>
         </div>

         <div className="contactus-container">
            <div className="contactus-inner-left-container">
               <div>
                  <h1 className="title">Contact Information</h1>
                  <h3 className="subtitle">
                     Start a conversation with us now!{" "}
                  </h3>
               </div>

               <div className="contacts-container">
                  <span>0956-716-2892 / 0998-428-9894/ 028-9618009</span>
                  <div>
                     <span>inbound@globalpinoytravel.com (Supplier)</span>
                     <span>inboundcorp@globalpinoytravel.com (Client)</span>
                  </div>
                  <span>
                     Unit 216 Pacific Century Tower, Barangay South Triangle,
                     Quezon Avenue, Quezon City.
                  </span>
               </div>

               <div className="bubble bubble-1"></div>
               <div className="bubble bubble-2"></div>
            </div>

            <form className="contact-form">
               <div className="input-group">
                  <div className="input-container">
                     <label className="form-label">First Name</label>
                     <input className="form-input" />
                  </div>

                  <div className="input-container">
                     <label className="form-label">Last Name</label>
                     <input className="form-input" />
                  </div>
               </div>

               <div className="input-group">
                  <div className="input-container">
                     <label className="form-label">Email</label>
                     <input className="form-input" />
                  </div>

                  <div className="input-container">
                     <label className="form-label">Phone Number</label>
                     <input className="form-input" />
                  </div>
               </div>

               <div className="input-container">
                  <label className="form-label">Subject</label>
                  <input className="form-input" />
               </div>

               <div className="input-container">
                  <label className="form-label">Message</label>
                  <input className="form-input" />
               </div>

               <button className="submit-button">Send Message</button>
            </form>
         </div>
      </div>
   );
};

export default ContactUsPage;
